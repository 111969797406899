
.header {
    padding-top: 15px;
    display: block;
}



h1 {
    display: inline-block;
    width: 600px;
}

a {
    text-align: right;
}

