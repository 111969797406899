

.columns input {
    margin: 5px;
}

.columns img {
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
}

.header5 {
    margin: 0px 0px 5px 0px;
}