.logoblock {
    display: inline-block;
    width: 20%;
    max-width: 200px; 
}

.textblock {
    display: inline-block;
    width: 75%;
    max-width: 750px;
    vertical-align: top;
}

.divider {
    background-image: linear-gradient(to right, white , grey 20%, grey 80%, white);
    height: 1px;
    width: 100%;
    margin: 10px auto;
}

