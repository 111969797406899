.background_image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -100;
  opacity: .5;
}

.App-header {
  background-color: white;
  max-width: 970px;
  margin: 0px auto;
  
}

.header-bar {
  background-color: white;
}

.main-container {
  max-width: 970px;
  margin: 0px auto;
}

.blurrywhite {
  display: inline-block;
  background-color: white;
  box-shadow: 0 0 5px 5px white;
  border-radius: 10px;
  margin: 15px;
  padding: 5px;
}

.fullpage {
  display: block;
  width:calc(100% - 40px);
  min-height:600px;
}

.smalllogo {
  max-width: 25px;
  max-height: 25px;
  margin: 2px;
}

.inlineblock {
  display: inline-block;
}

.smmarg {
  margin: 0px  10px 2px 0px;
}

.leftmarg {
  margin-left: 5px;
}

.spaced {
  display:inline-block; 
  margin: 15px 0px;
}

.shortinput {
  width: 50px;
}

.veryshortinput {
  width: 40px;
}
.halfcolumn {
  display: inline-block;
  width: 465px;
  vertical-align: top;   
  margin: 0px;
  padding: 0px;
}
.halfsection {
  width: 420px;
  display: inline-block;
  vertical-align: top;   
  border: 1px solid lightgrey;
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
}

.nodec {
  text-decoration: none;
}

.criteriamed {
  width: 250px;
}

.crteriaham {
  width: 400px;
}