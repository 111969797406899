.smallplan {
    width: 200px;
    display: inline-block;
    background-color: white;
    box-shadow: 0 0 5px 5px white;
    border-radius: 10px;
    margin: 15px;
    padding: 5px;
}

.heading {
    text-align: left;
    margin: 0px 0px 5px 0px;
}
