.floatingburger {
    position: absolute;
    z-index: 100;

}

.relativepos {
    position: relative;
    min-height: 100%;
}

.criteria_summary {
    background-color: white;
    width: calc(100% - 40px);
}

